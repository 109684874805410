import { useMemo } from "react"

import { useUrlParam } from "src/hooks/useUrlParam"

import { TTableSort } from "./useTableColumns"

const KEY = "sort_by"
const SPLITTER = ":"

export function useSortParam(initialSort?: TTableSort): {
  sortValue: TTableSort | null
  setSortValue: (newSortValue: TTableSort) => void
} {
  const { value, replaceValue } = useUrlParam(KEY, {
    initialValue: initialSort && convertToSearchParam(initialSort),
  })

  const sortValue = useMemo(() => {
    const splittedSortParam = value?.split(SPLITTER)

    if (
      splittedSortParam &&
      splittedSortParam[0] &&
      (splittedSortParam[1] === "asc" || splittedSortParam[1] === "desc")
    ) {
      const sortId = splittedSortParam[0]
      const sortOrder = splittedSortParam[1]

      return {
        id: sortId,
        order: sortOrder as "desc" | "asc",
      }
    }

    return null
  }, [value])

  function setSortValue(newSortValue: TTableSort) {
    replaceValue(convertToSearchParam(newSortValue))
  }

  return {
    sortValue,
    setSortValue,
  }
}

function convertToSearchParam(sort: TTableSort) {
  return `${sort.id}:${sort.order}`
}
